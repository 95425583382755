
import VueObserveVisibility from 'vue-observe-visibility';
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { ObjectType } from '@/@types/graphql-types';

const AvailabilityBadge = () => import('@/components/poster/AvailabilityBadge.vue');
const PosterQuickActionsGraphql = () => import('@/components/poster/PosterQuickActionsGraphql.vue');
const ProviderIcon = () => import('@/components/picture/ProviderIcon.vue');
const TitlePosterRibbonGraphqlLite = () => import('@/components/poster/TitlePosterRibbonGraphqlLite.vue');

import PictureComp, { PictureCompEvent } from '@/components/picture/PictureComp.vue';

import { ThumborOptions } from '@/filters/thumbor';

import { diffInDays, diffInMilliseconds } from '@/helpers/date-helper';

import ImpressionTrackingMixin from '@/mixins/ImpressionTrackingMixin.vue';
import UsePosterImpressionTracking, {
	type ImpressionTrackingPosterDetails,
} from '@/helpers/composables/usePosterImpressionTracking';
import { AvailabilityBadgeSize, AvailabilityBadgeType } from './types/availability-badge';

import type { PosterGraphqlProps } from '@/interfaces/components/poster/PosterGraphql';
import type { PosterQuickActionsGraphqlProps } from '@/interfaces/components/poster/PosterQuickActionsGraphql';
import type { UserSettings } from '@/stores/user.store';
import { WebLocale } from '@/enums/web-locale';
import { getDisneyImpressionUrl } from '@/helpers/provider-promotion';

Vue.use(VueObserveVisibility);

const User = namespace('user');
const Language = namespace('language');

const FreeTag = () => import('./FreeTag.vue');
const ImpressionPixel = () =>
	import(/* webpackChunkName: "impression-pixel" */ '@/components/sponsored-recommendations/ImpressionPixel.vue');

@Component({
	name: 'PosterGraphql',
	components: {
		TitlePosterRibbonGraphqlLite,
		PosterQuickActionsGraphql,
		PictureComp,
		ProviderIcon,
		AvailabilityBadge,
		FreeTag,
		ImpressionPixel,
		UsePosterImpressionTracking,
	},
})
export default class PosterGraphql extends Mixins(ImpressionTrackingMixin) {
	PictureCompEvent = PictureCompEvent;
	ObjectType = ObjectType;

	@Prop({ required: true }) declare titleId: PosterGraphqlProps['titleId'];
	@Prop({ required: true }) declare titleObjectId: PosterGraphqlProps['titleObjectId'];
	@Prop({ required: true }) declare titleObjectType: PosterGraphqlProps['titleObjectType'];
	@Prop({ default: '' }) titleName: PosterGraphqlProps['titleName'];
	@Prop({ default: '' }) titlePosterUrl: PosterGraphqlProps['titlePosterUrl'];
	@Prop({ default: () => [] }) titleBackdrops: PosterGraphqlProps['titleBackdrops'];
	@Prop({ default: null }) titleScoring: PosterGraphqlProps['titleScoring'];
	@Prop({ default: null }) titleSeasonNumber: PosterGraphqlProps['titleSeasonNumber'];
	@Prop({ default: null }) titleAvailableTo: PosterGraphqlProps['titleAvailableTo'];
	@Prop({ default: null }) titleUpcomingReleases: PosterGraphqlProps['titleUpcomingReleases'];
	@Prop({ default: null }) seenEpisodeCount: PosterGraphqlProps['seenEpisodeCount'];
	@Prop({ default: false }) isTvShowTracked: PosterGraphqlProps['isTvShowTracked'];
	@Prop({ default: false }) isInCustomList: PosterGraphqlProps['isInCustomList'];
	@Prop({ default: false }) isInWatchlist: PosterGraphqlProps['isInWatchlist'];
	@Prop({ default: false }) isInSeenList: PosterGraphqlProps['isInSeenList'];
	@Prop({ default: false }) isInLikeList: PosterGraphqlProps['isInLikeList'];
	@Prop({ default: false }) isInDislikeList: PosterGraphqlProps['isInDislikeList'];
	@Prop({ default: true }) isTitleReleased: PosterGraphqlProps['isTitleReleased'];
	@Prop({ default: null }) watchNowOffer: PosterGraphqlProps['watchNowOffer'];

	@Prop({ default: 0 }) freeOffersCount: PosterGraphqlProps['freeOffersCount'];
	@Prop({ default: false }) showFreeTag: PosterGraphqlProps['showFreeTag'];
	@Prop({ default: () => [] }) providerIds: PosterGraphqlProps['providerIds'];

	// Seasons are marked as watchlisted based on full show instead of episode titles, so we have to pass both.
	@Prop({ default: 'poster' }) imageProfile: PosterGraphqlProps['imageProfile'];
	@Prop({ default: false }) showRibbon: PosterGraphqlProps['showRibbon'];
	@Prop({ default: false }) showQuickActions: PosterGraphqlProps['showQuickActions'];
	@Prop({ default: false }) forceQuickActions: PosterGraphqlProps['forceQuickActions'];
	@Prop({ default: false }) hasMoreLikeThis: PosterGraphqlProps['hasMoreLikeThis'];
	@Prop({ default: true }) showWatchNowButton: PosterGraphqlProps['showWatchNowButton'];
	@Prop({ default: true }) showTvBadge: PosterGraphqlProps['showTvBadge'];
	@Prop({ default: false }) showAvailabilityBadge: PosterGraphqlProps['showAvailabilityBadge'];
	@Prop({ default: AvailabilityBadgeSize.Long }) availabilityBadgeSize: PosterGraphqlProps['availabilityBadgeSize'];
	@Prop({ default: true }) lazy: PosterGraphqlProps['lazy'];
	@Prop({ default: '' }) clickSource: PosterGraphqlProps['clickSource'];
	@Prop({ default: '' }) noRadius: PosterGraphqlProps['noRadius'];
	@Prop({ default: false }) smallRadius: PosterGraphqlProps['smallRadius'];
	@Prop({ default: false }) smallBubbles: PosterGraphqlProps['smallBubbles'];
	@Prop({ default: 'eager' }) loading: PosterGraphqlProps['loading'];

	@Prop({ default: false }) smallPosters: boolean;

	@User.State settings: UserSettings;
	@User.Getter isLoggedIn: boolean;

	@Language.State webLocale: WebLocale;

	get observable() {
		return this.getObservableOptions(this.titleId);
	}

	get hasFreeOffers() {
		return (this.freeOffersCount as number) > 0 && this.showFreeTag;
	}

	get titleSlug() {
		const fullPath = this.titlePosterUrl?.match(/([^\/]*)$/);
		return fullPath && fullPath[0] ? fullPath[0] : 'Poster';
	}

	get imageUrl(): string {
		// TODO [PictureComp] Refactor PictureComp to be able to use new url templating /poster/poster_id/{profile}/movie_name.{format}
		return Vue.filter('Thumbor')({
			url: this.titlePosterUrl,
			imageType: this.imageProfile,
		} as ThumborOptions);
	}

	get showTVShowBadge() {
		return this.showTvBadge && [ObjectType.Show, ObjectType.ShowSeason].includes(this.titleObjectType);
	}

	get isShowAvailabilityBadge() {
		// Since we display availability badge only on Title Details page we need to minimize impact on other pages,
		// that is why we pass showAvailabilityBadge prop, that enable calculations only when needed
		return this.showAvailabilityBadge && this.isLoggedIn && !!this.availabilityBadgeDate;
	}

	get upcomingReleaseFromSelectedPackages() {
		const userPackages = this.settings.providers;

		return (
			this.titleUpcomingReleases
				?.filter(item => diffInMilliseconds(new Date(item.releaseDate!)) > 0)
				.filter(item => (item?.releaseCountDown ?? diffInDays(new Date(item.releaseDate!))) < 30)
				.find(item => userPackages.includes(item.package?.shortName!)) || null
		);
	}

	get leavingOfferFromSelectedPackages() {
		const userPackages = this.settings.providers;

		return (
			this.titleAvailableTo
				?.filter(item => (item?.availableCountDown ?? diffInDays(new Date(item.availableToDate))) < 30)
				.find(item => userPackages.includes(item.package?.shortName)) || null
		);
	}

	get availabilityBadgeType() {
		return !!this.upcomingReleaseFromSelectedPackages
			? AvailabilityBadgeType.Upcoming
			: AvailabilityBadgeType.Leaving;
	}

	get availabilityBadgeDate() {
		if (!!this.upcomingReleaseFromSelectedPackages) {
			return this.upcomingReleaseFromSelectedPackages.releaseDate;
		}

		if (!!this.leavingOfferFromSelectedPackages) {
			return this.leavingOfferFromSelectedPackages.availableToDate;
		}

		return null;
	}

	get availabilityBadgeCountdown() {
		if (!!this.upcomingReleaseFromSelectedPackages) {
			return this.upcomingReleaseFromSelectedPackages.releaseCountDown;
		}

		if (!!this.leavingOfferFromSelectedPackages) {
			return this.leavingOfferFromSelectedPackages.availableCountDown;
		}

		return null;
	}

	posterQuickActionsGraphqlProps(): PosterQuickActionsGraphqlProps {
		return {
			forceRender: this.forceQuickActions,
			isTvShowTracked: this.isTvShowTracked,
			isInCustomList: this.isInCustomList,
			isInWatchlist: this.isInWatchlist,
			isInSeenList: this.isInSeenList,
			isInLikeList: this.isInLikeList,
			isInDislikeList: this.isInDislikeList,
			isTitleReleased: this.isTitleReleased,
			titleId: this.titleId,
			titleObjectType: this.titleObjectType,
			titleObjectId: this.titleObjectId,
			titleName: this.titleName,
			titlePosterUrl: this.titlePosterUrl,
			titleBackdrops: this.titleBackdrops,
			titleScores: this.titleScoring,
			titleSeenEpisodeCount: this.seenEpisodeCount,
			titleSeasonNumber: this.titleSeasonNumber,
			watchNowOffer: this.watchNowOffer,
			additionalContexts: this.additionalContexts,
			hasMoreLikeThis: this.hasMoreLikeThis,
			showWatchNowButton: this.showWatchNowButton,
			isSimilarTitle: this.clickSource === 'similartitles',
			smallBubbles: this.smallBubbles,
		};
	}

	get posterImpressionTitle(): ImpressionTrackingPosterDetails {
		return {
			titleId: this.titleObjectId,
			entityId: this.titleId,
			objectType: this.titleObjectType,
			seasonNumber: this.titleSeasonNumber,
		};
	}

	// IMPRESSION PIXEL //
	mountImpressionPixel = false;

	get externalImpressionTag() {
		return getDisneyImpressionUrl(this.watchNowOffer?.package?.packageId, this.webLocale);
	}

	visibilityCallback(isVisible: boolean) {
		if (isVisible && !this.mountImpressionPixel && this.externalImpressionTag) {
			this.mountImpressionPixel = true;
		}
	}
	// IMPRESSION PIXEL END //
}
